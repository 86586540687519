/* eslint-disable no-use-before-define */
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    Action,
    Actions,
    createSelector,
    ofAction,
    Selector,
    State,
    StateContext,
} from '@ngxs/store';
import {
    equals,
} from 'ramda';
import {
    EMPTY,
    map,
    Observable,
    takeUntil,
    tap,
} from 'rxjs';

import {
    MY_PORTFOLIO_UNIVERSE_ID,
    PORTFOLIOS_SEARCH_FIELDS,
} from '~/app/core/constants/portfolios.constants';
import { SHARES_SEARCH_FIELDS } from '~/app/core/constants/shares.constants';
import {
    ACTIVE_RISKS_FOR_SIMULATION,
    RISK_INDICATORS_FOR_SIMULATION,
    SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES,
} from '~/app/core/constants/simulation-scenario.constants';
import { MarketEventsService } from '~/app/core/services/api/market-events/market-events.service';
import { PortfoliosService } from '~/app/core/services/api/portfolios/portfolios.service';
import { ScenariosService } from '~/app/core/services/api/scenarios/scenarios.service';
import { SharesService } from '~/app/core/services/api/shares/shares.service';
import { GetAuthorisationAction } from '~/app/core/state/authorisation/authorisation.action';
import { ColorUtilsService } from '~/app/core/utils/color-utils/color-utils.service';
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { Period } from '~/app/shared/enums/period.enum';
import {
    SimulationScenarioMarketEventDisplayMode,
} from '~/app/shared/enums/simulation-scenario-market-event-display-mode.enum';
import { SimulationScenarioViewModeValue } from '~/app/shared/enums/simulation-scenario-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { ComparedEntity } from '~/app/shared/types/compared-entity.type';
import { MarketEvent } from '~/app/shared/types/market-event.type';
import { MarketEventSearchRequest } from '~/app/shared/types/market-events-search-request.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { Portfolio } from '~/app/shared/types/portfolio/portfolio.type';
import { ShareCategory } from '~/app/shared/types/shares/share-category.type';
import { Share } from '~/app/shared/types/shares/share.type';
import {
    SimulationCommonBenchmarkParam,
} from '~/app/shared/types/simulation-common/simulation-common-benchmark-param.type';
import { ActiveScenario } from '~/app/shared/types/simulation-scenario/simulation-scenario-active-scenario.type';
import {
    SimulationScenarioCreateRequest,
    SimulationScenarioUpdateRequest,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-edit-request.type';
import {
    SimulationScenarioEditedScenario,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-edited-scenario.type';
import {
    SimulationScenarioMarketEventDisplay,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-market-event-display.type';
import {
    SimulationScenarioMarketEventsSearchCriterias,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-market-events-search-criterias.type';
import { SimulationScenarioParams } from '~/app/shared/types/simulation-scenario/simulation-scenario-params.type';
import { SimulationScenarioResponse } from '~/app/shared/types/simulation-scenario/simulation-scenario-response.type';

import {
    AddAllocationsToComparedEntitiesAction,
    AddCategoriesToComparedEntitiesAction,
    AddComparisonListToComparedEntitiesAction,
    AddCreatingPortfolioToComparedEntitiesAction,
    AddPortfoliosToComparedEntitiesAction,
    AddSharesToComparedEntitiesAction,
    CancelLaunchSimulationAction,
    ChangeViewModeAction,
    CopyActiveInDraftAction,
    CreateScenarioAction,
    DeleteAllComparedEntitiesAction,
    DeleteComparedEntityAction,
    DeleteScenarioAction,
    GetScenarioAction,
    InitActiveScenarioAction,
    LaunchSimulationAction,
    ResetAction,
    ResetCurrentScenarioAction,
    ResetViewModeDisplayAction,
    SearchMarketEventsAction,
    SearchMarketEventsTextAction,
    SelectScenarioAction,
    SetViewModeDisplayValuesAction,
    ToggleBenchmarkAction,
    UpdateEditedScenarioAction,
    UpdateMarketEventDisplayAction,
    UpdateMarketEventsSearchCriteriasAction,
    UpdateScenarioAction,
} from './simulation-scenario.actions';
import { ComparisonListsService } from '../../services/api/comparison-lists/comparison-lists.service';

export type SimulationScenarioStateModel = {
    draft: SimulationScenarioParams,
    active: SimulationScenarioParams,
    simulation: SimulationScenarioResponse | null,
    editedScenario: SimulationScenarioEditedScenario,
    viewModeDisplay: {
        [key: string]: SimulationScenarioViewModeValue[],
    },
    activeScenario: ActiveScenario,
    loadingActiveScenario: boolean,
    marketEventDisplayMode: SimulationScenarioMarketEventDisplay,
    marketEvents: {
        query: string | null,
        items: MarketEvent[],
        isLoading: boolean,
        filters: SimulationScenarioMarketEventsSearchCriterias,
    },
    viewMode: ViewModeType,
    requestId: string | null,
}

const editedScenarioDefaults: SimulationScenarioEditedScenario = {
    historicalPeriod: undefined,
    id: '',
    name: '',
    marketEvents: [],
};

const defaults = {
    draft: {
        comparedEntities: [],
        benchmark: null,
        scenarioId: null,
        marketEventIds: null,
        historicalPeriod: null,
    },
    active: {
        comparedEntities: [],
        benchmark: null,
        scenarioId: null,
        marketEventIds: null,
        historicalPeriod: null,
    },
    editedScenario: editedScenarioDefaults,
    simulation: null,
    viewModeDisplay: {
        PRO: SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES.PRO,
        MAIN: SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES.MAIN,
        ADVANCED: SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
        SHARED: [],
    },
    activeScenario: {
        activeMarketEvents: [],
        activeHistoricalPeriod: null,
    },
    loadingActiveScenario: false,
    marketEventDisplayMode: {
        mode: SimulationScenarioMarketEventDisplayMode.SEVERAL_EVENTS,
        eventOrderSelected: 0,
    },
    marketEvents: {
        items: [],
        query: null,
        filters: {
            marketCycles: [],
            contexts: [],
            geographies: [],
            duration: {
                minValue: null,
                maxValue: null,
            },
        },
        isLoading: false,
    },
    viewMode: ViewModeType.MAIN,
    requestId: null,
};

@State<SimulationScenarioStateModel>({
    name: 'simulationScenario',
    defaults,
})
@Injectable()
export class SimulationScenarioState {
    constructor(
        public sharesService: SharesService,
        public portfoliosService: PortfoliosService,
        public scenariosService: ScenariosService,
        private marketEventsService: MarketEventsService,
        private colorsUtilsService: ColorUtilsService,
        private transloco: TranslocoService,
        private actions$: Actions,
        private comparisonListsService: ComparisonListsService,
    ) {
    }

    static isDisplayedInCurrentViewMode(viewModeValue: SimulationScenarioViewModeValue) {
        // eslint-disable-next-line max-len
        return createSelector([SimulationScenarioState], (state: SimulationScenarioStateModel) => state.viewModeDisplay[state.viewMode].some((item) => item === viewModeValue));
    }

    @Selector()
    static getDraftComparedEntities(state: SimulationScenarioStateModel): ComparedEntity<Share | Portfolio | ShareCategory | CreatePortfolio>[] {
        const entityBenchmark = state.draft.comparedEntities
            .find((comparedEntity) => comparedEntity.id === state.draft.benchmark?.id && comparedEntity.type === state.draft.benchmark?.type);
        return [
            ...(entityBenchmark ? [entityBenchmark] : []),
            ...state.draft.comparedEntities.reduce((acc: ComparedEntity<Share | Portfolio | ShareCategory | CreatePortfolio>[], comparedEntity) => {
                if (comparedEntity.id !== entityBenchmark?.id || comparedEntity.type !== entityBenchmark.type) {
                    acc.push({
                        ...comparedEntity,
                    });
                }
                return acc;
            }, []),
        ];
    }

    @Selector()
    static getDraftParams(state: SimulationScenarioStateModel): SimulationScenarioParams {
        return state.draft;
    }

    @Selector()
    static getDraftComparedEntitiesWithoutData(state: SimulationScenarioStateModel): ComparedEntity[] {
        return state.draft.comparedEntities;
    }

    @Selector()
    static getDraftBenchmark(state: SimulationScenarioStateModel): SimulationCommonBenchmarkParam {
        return state.draft.benchmark;
    }

    @Selector()
    static getDraftComparedEntitiesCount(state: SimulationScenarioStateModel): number {
        return state.draft.comparedEntities.length;
    }

    @Selector()
    static getDraftComparedPortfoliosEntitiesCount(state: SimulationScenarioStateModel): number {
        return state.draft.comparedEntities.filter((item) => item.type === EntityType.PORTFOLIO || item.type === EntityType.ALLOCATION).length;
    }

    @Selector()
    static getEditedScenario(state: SimulationScenarioStateModel): SimulationScenarioEditedScenario {
        return state.editedScenario;
    }

    @Selector()
    static getDraftSelectedScenarioId(state: SimulationScenarioStateModel): string | null {
        return state.draft.scenarioId;
    }

    @Selector()
    static getActiveComparedEntities(state: SimulationScenarioStateModel): ComparedEntity<Share | Portfolio | ShareCategory | CreatePortfolio>[] {
        return [
            ...state.active.comparedEntities.map((comparedEntity) => ({
                ...comparedEntity,
                isReference: state.active.benchmark?.id === comparedEntity.id && state.active.benchmark.type === comparedEntity.type,
            })),
        ];
    }

    @Selector()
    static getActiveBenchmark(state: SimulationScenarioStateModel): SimulationCommonBenchmarkParam {
        return state.active.benchmark;
    }

    @Selector()
    static getActiveComparedEntitiesWithoutData(state: SimulationScenarioStateModel): ComparedEntity[] {
        return state.active.comparedEntities;
    }

    @Selector()
    static getActiveComparedEntitiesCount(state: SimulationScenarioStateModel): number {
        return state.active.comparedEntities.length;
    }

    @Selector()
    static getActiveParams(state: SimulationScenarioStateModel): SimulationScenarioParams {
        return state.active;
    }

    @Selector()
    static getActiveMarketEvents(state: SimulationScenarioStateModel): MarketEvent[] {
        return state.activeScenario.activeMarketEvents;
    }

    @Selector()
    static getActiveHistoricalPeriod(state: SimulationScenarioStateModel): Period | null {
        return state.activeScenario.activeHistoricalPeriod;
    }

    @Selector()
    static getActiveScenario(state: SimulationScenarioStateModel): ActiveScenario {
        return state.activeScenario;
    }

    @Selector()
    static getLoadingActiveScenario(state: SimulationScenarioStateModel): boolean {
        return state.loadingActiveScenario;
    }

    @Selector()
    static getMarketEventDisplayMode(state: SimulationScenarioStateModel): SimulationScenarioMarketEventDisplay {
        return state.marketEventDisplayMode;
    }

    @Selector()
    static getMarketEventsIsLoading(state: SimulationScenarioStateModel): boolean {
        return state.marketEvents.isLoading;
    }

    @Selector()
    static getMarketEventsItems(state: SimulationScenarioStateModel): MarketEvent[] {
        return state.marketEvents.items;
    }

    @Selector()
    static getEditedScenarioMarketEventsCount(state: SimulationScenarioStateModel): number {
        return state.editedScenario.marketEvents.length;
    }

    @Selector()
    static getMarketEventsQuery(state: SimulationScenarioStateModel): string | null {
        return state.marketEvents.query;
    }

    @Selector()
    static getMarketEventsFilters(state: SimulationScenarioStateModel): SimulationScenarioMarketEventsSearchCriterias {
        return state.marketEvents.filters;
    }

    @Selector()
    static getRequestId(state: SimulationScenarioStateModel): string | null {
        return state.requestId;
    }

    @Selector()
    static getSimulation(state: SimulationScenarioStateModel): SimulationScenarioResponse | null {
        return state.simulation;
    }

    @Selector()
    static getViewModeDisplay(state: SimulationScenarioStateModel) {
        return state.viewModeDisplay[state.viewMode];
    }

    @Selector()
    static getViewModeParam(state: SimulationScenarioStateModel): ViewModeType {
        return state.viewMode;
    }

    @Selector()
    static getEditedScenarioId(state: SimulationScenarioStateModel): string | null {
        return state?.editedScenario?.id || null;
    }

    @Action(ResetAction)
    public reset({ setState, getState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();
        setState({
            ...defaults,
            viewMode: state.viewMode ? state.viewMode : defaults.viewMode,
        });
    }


    @Action(UpdateEditedScenarioAction)
    public updateEditedScenario({
        getState,
        patchState,
    }: StateContext<SimulationScenarioStateModel>, action: UpdateEditedScenarioAction) {
        const state = getState();
        patchState({
            ...state,
            draft: {
                ...state.draft,
                ...(action.newEditedScenario.marketEvents !== undefined
                    ? { marketEventIds: action.newEditedScenario.marketEvents.map((marketEvent) => marketEvent.id) }
                    : {}),
                ...(action.newEditedScenario.historicalPeriod !== undefined ? { historicalPeriod: action.newEditedScenario.historicalPeriod } : {}),
            },

            editedScenario: {
                ...action.newEditedScenario,
                name: (action?.newEditedScenario)?.name?.length
                    ? action.newEditedScenario.name
                    : this.transloco.translate('simulation.scenario.scenario_name_placeholder'),
            },
        });
    }


    @Action(AddSharesToComparedEntitiesAction)
    public addSharesToComparedEntities({
        getState,
        patchState,
    }: StateContext<SimulationScenarioStateModel>, action: AddSharesToComparedEntitiesAction) {
        return this.getSharesData(action.shares.map((share) => share.id)).pipe(
            tap((shares) => {
                const state = getState();

                const draftComparedSharesEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.SHARE)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...shares
                        .filter((share) => !draftComparedSharesEntitiesIds.includes(share.id))
                        .map((share) => {
                            const shareParam = action.shares.find((sha) => sha.id === share.id);
                            return {
                                id: share.id,
                                name: share.name,
                                isReference: false,
                                type: EntityType.SHARE,
                                data: {
                                    ...share,
                                    ...(shareParam?.srri
                                        ? { srri: shareParam?.srri }
                                        : {}),
                                },
                            };
                        }),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        ...state.draft,
                        comparedEntities,
                    },
                });
            }),
        );
    }

    @Action(AddPortfoliosToComparedEntitiesAction)
    public addPortfoliosToComparedEntities({ getState, patchState }: StateContext<SimulationScenarioStateModel>,
        action: AddPortfoliosToComparedEntitiesAction) {
        return this.getPortfoliosData(action.portfolios.map((portfolio) => portfolio.id)).pipe(
            tap((portfolios) => {
                const state = getState();

                const draftComparedPortfoliosEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.PORTFOLIO)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...portfolios
                        .filter((portfolio) => !draftComparedPortfoliosEntitiesIds.includes(portfolio.id))
                        .map((portfolio) => {
                            const portfolioParam = action.portfolios.find((ptf) => ptf.id === portfolio.id);
                            return {
                                id: portfolio.id,
                                name: portfolio.name,
                                isReference: false,
                                type: EntityType.PORTFOLIO,
                                data: {
                                    ...portfolio,
                                    ...(portfolioParam?.srri
                                        ? { srri: portfolioParam?.srri }
                                        : {}),
                                },
                            };
                        }),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        ...state.draft,
                        comparedEntities,
                    },
                });
            }),
        );
    }

    @Action(AddAllocationsToComparedEntitiesAction)
    public addAllocationsToComparedEntities({ getState, patchState }: StateContext<SimulationScenarioStateModel>,
        action: AddAllocationsToComparedEntitiesAction) {
        const portfolioIds = action.allocations.map((alloc) => Math.abs(alloc.allocation.id));

        return this.getPortfoliosData(portfolioIds).pipe(
            tap((portfolios) => {
                const state = getState();

                const draftComparedAllocationsEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.PORTFOLIO || item.type === EntityType.ALLOCATION)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...portfolios
                        .filter((portfolio) => !draftComparedAllocationsEntitiesIds.includes(portfolio.id))
                        .map((portfolio) => {
                            const allocation = action.allocations.find((alloc) => alloc.allocation.id === -portfolio.id);
                            return {
                                id: portfolio.id,
                                name: this.transloco.translate('projection.current_portfolio', { name: portfolio.name }),
                                isReference: false,
                                isLock: true,
                                type: EntityType.PORTFOLIO,
                                data: {
                                    ...portfolio,
                                    srri: allocation?.calculatedSrri ?? 1,
                                },
                            };
                        }),
                    ...portfolios
                        .filter((portfolio) => !draftComparedAllocationsEntitiesIds.includes(-portfolio.id))
                        .map((portfolio) => {
                            const allocation = action.allocations.find((alloc) => alloc.allocation.id === -portfolio.id);
                            return {
                                id: -portfolio.id,
                                name: this.transloco.translate('projection.updating_portfolio', { name: portfolio.name }),
                                isReference: false,
                                isLock: true,
                                type: EntityType.ALLOCATION,
                                extraData: allocation,
                                data: {
                                    ...portfolio,
                                    srri: allocation?.newCalculatedSrri ?? (allocation?.calculatedSrri ?? 1),
                                },
                            };
                        }),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        ...state.draft,
                        comparedEntities,
                    },
                });
            }),
        );
    }

    @Action(AddCreatingPortfolioToComparedEntitiesAction)
    public addCreatingPortfolioToComparedEntities({ getState, patchState }: StateContext<SimulationScenarioStateModel>,
        action: AddCreatingPortfolioToComparedEntitiesAction) {
        const state = getState();

        let comparedEntities = [
            ...state.draft.comparedEntities,
            ...(action.initialAllocation ? [{
                id: -1,
                name: this.transloco.translate('projection.initial_new_portfolio', { name: action.portfolio.name }),
                isReference: false,
                isLock: true,
                type: EntityType.ALLOCATION,
                extraData: {
                    allocation: {
                        id: -1,
                        type: EntityType.ALLOCATION,
                        allocation: {
                            shares: action.initialAllocation.shares,
                            cashes: action.initialAllocation.cashes,
                        },
                        currency: action.initialAllocation.currency,
                    },
                    calculatedSrri: action.portfolio.calculatedSrri ?? 1,
                },
                data: {
                    ...action.portfolio,
                    srri: action.portfolio.calculatedSrri ?? 1,
                },
            }] : []),
            {
                id: 0,
                name: this.transloco.translate('projection.new_portfolio', { name: action.portfolio.name }),
                isReference: false,
                isLock: true,
                type: EntityType.ALLOCATION,
                extraData: {
                    allocation: action.allocation,
                    calculatedSrri: action.portfolio.calculatedSrri ?? 1,
                },
                data: {
                    ...action.portfolio,
                    srri: action.portfolio.calculatedSrri ?? 1,
                },
            },
        ];

        const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

        comparedEntities = comparedEntities.map((item, index) => ({
            ...item,
            color: colors[index],
        }));

        patchState({
            draft: {
                ...state.draft,
                comparedEntities,
            },
        });
    }

    @Action(AddCategoriesToComparedEntitiesAction)
    public addCategoriesToComparedEntities(
        { getState, patchState }: StateContext<SimulationScenarioStateModel>,
        action: AddCategoriesToComparedEntitiesAction,
    ) {
        return this.getCategoriesData(action.categoryIds).pipe(
            tap((categories) => {
                const state = getState();

                const draftComparedCategoriesEntitiesIds = state.draft.comparedEntities
                    .filter((item) => item.type === EntityType.CATEGORY)
                    .map((item) => item.id);

                let comparedEntities = [
                    ...state.draft.comparedEntities,
                    ...categories
                        .filter((category) => !draftComparedCategoriesEntitiesIds.includes(category.id))
                        .map((category) => ({
                            id: category.id,
                            name: category.name,
                            isReference: false,
                            type: EntityType.CATEGORY,
                            data: category,
                        })),
                ];

                const colors = this.colorsUtilsService.getThemeColors(comparedEntities.length);

                comparedEntities = comparedEntities.map((item, index) => ({
                    ...item,
                    color: colors[index],
                }));

                patchState({
                    draft: {
                        ...state.draft,
                        comparedEntities,
                    },
                });
            }),
        );
    }

    @Action(AddComparisonListToComparedEntitiesAction)
    public addComparisonListToComparedEntities({ dispatch }: StateContext<SimulationScenarioStateModel>, action: AddComparisonListToComparedEntitiesAction) {
        return this.comparisonListsService.getComparisonListEntities(action.listId)
            .pipe(
                tap((items) => {
                    const shares = items.filter((item) => item.type === 'share');
                    const portfolios = items.filter((item) => item.type === 'portfolio');
                    const categories = items.filter((item) => item.type === 'category');

                    if (shares.length > 0) {
                        dispatch(new AddSharesToComparedEntitiesAction([
                            ...shares.map((share) => ({ id: share.id })),
                        ]));
                    }

                    if (portfolios.length > 0) {
                        dispatch(new AddPortfoliosToComparedEntitiesAction([
                            ...portfolios.map((portfolio) => ({ id: portfolio.id })),
                        ]));
                    }

                    if (categories.length > 0) {
                        dispatch(new AddCategoriesToComparedEntitiesAction([
                            ...categories.map((category) => category.id),
                        ]));
                    }
                }),
            );
    }

    @Action(DeleteComparedEntityAction)
    public deleteComparedEntity({
        getState,
        patchState,
    }: StateContext<SimulationScenarioStateModel>, action: DeleteComparedEntityAction) {
        const state = getState();
        patchState({
            draft: {
                ...state.draft,
                comparedEntities: state.draft.comparedEntities.filter((entity) => entity.id !== action.entityId || entity.type !== action.entityType),
            },
        });
    }

    @Action(DeleteScenarioAction)
    public deleteScenario(_: StateContext<SimulationScenarioStateModel>, action: DeleteScenarioAction) {
        return this.scenariosService.deleteScenario(action.scenarioId);
    }

    @Action(CreateScenarioAction)
    public createNewScenario({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();
        const scenarioEditRequest = {
            historicalPeriod: state?.editedScenario?.historicalPeriod,
            marketEventIds: state?.editedScenario?.marketEvents?.map((marketEvent) => marketEvent.id) || [],
            name: state?.editedScenario?.name,
        } as SimulationScenarioCreateRequest;
        return this.scenariosService.createScenario(scenarioEditRequest).pipe(
            tap((createdScenario) => {
                patchState({
                    draft: {
                        ...state.draft,
                        scenarioId: createdScenario.id,
                        marketEventIds: createdScenario.marketEvents.map((marketEvent) => marketEvent.id),
                        historicalPeriod: createdScenario.historicalPeriod,
                    },
                    editedScenario: {
                        ...createdScenario,
                    },
                });
            }),
        );
    }

    @Action(UpdateScenarioAction)
    public updateScenario({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();
        const scenarioEditRequest = {
            historicalPeriod: state?.editedScenario?.historicalPeriod,
            id: state?.editedScenario?.id,
            marketEventIds: state?.editedScenario?.marketEvents?.map((marketEvent) => marketEvent.id) || [],
            name: state?.editedScenario?.name,
        } as SimulationScenarioUpdateRequest;
        return this.scenariosService.updateScenario(scenarioEditRequest).pipe(
            tap((updatedScenario) => {
                patchState({
                    draft: {
                        ...state.draft,
                        marketEventIds: updatedScenario.marketEvents.map((marketEvent) => marketEvent.id),
                        historicalPeriod: updatedScenario.historicalPeriod,
                    },
                    editedScenario: {
                        ...updatedScenario,
                    },
                });
            }),
        );
    }


    @Action(DeleteAllComparedEntitiesAction)
    public deleteAllComparedEntities({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();
        patchState({
            draft: {
                ...state.draft,
                comparedEntities: state.draft.comparedEntities.reduce((acc: ComparedEntity[], comparedEntity) => {
                    if (comparedEntity.isLock) {
                        acc.push(comparedEntity);
                    }
                    return acc;
                }, []),
            },
        });
    }

    @Action(ToggleBenchmarkAction)
    public toggleBenchmark({
        getState,
        patchState,
    }: StateContext<SimulationScenarioStateModel>, action: ToggleBenchmarkAction) {
        const state = getState();
        if (!action.benchmark || (state.draft.benchmark?.id === action.benchmark.id && state.draft.benchmark?.type === action.benchmark.type)) {
            patchState({
                draft: {
                    ...state.draft,
                    benchmark: null,
                },
            });
        } else {
            patchState({
                draft: {
                    ...state.draft,
                    benchmark: action.benchmark,
                },
            });
        }
    }

    @Action(LaunchSimulationAction, { cancelUncompleted: true })
    public launchSimulation({ getState, patchState, dispatch }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();

        const simulationParams = {
            historicalPeriod: state.draft.historicalPeriod,
            marketEventIds: state.draft.marketEventIds,
            scenarioId: state.draft.marketEventIds ? null : state.draft.scenarioId,
            requestId: state.requestId,
            benchmark: state.draft.benchmark ? {
                id: state.draft.benchmark.id,
                type: state.draft.benchmark.type,
                ...(state.draft.benchmark.type === EntityType.ALLOCATION ? {
                    currency: state.draft.comparedEntities.find((entity) => entity.id === state.draft.benchmark?.id
                        && entity.type === state.draft.benchmark.type)?.extraData?.allocation.currency,
                    allocation: state.draft.comparedEntities.find((entity) => entity.id === state.draft.benchmark?.id
                        && entity.type === state.draft.benchmark.type)?.extraData?.allocation.allocation,
                } : {}),
            } : null,
            references: state.draft.comparedEntities
                .filter((entity) => (state.draft.benchmark ? entity.id !== state.draft.benchmark.id || entity.type !== state.draft.benchmark.type : true))
                .map((entity) => ({
                    id: entity.id,
                    type: entity.type,
                    ...(entity.type === EntityType.ALLOCATION ? {
                        currency: entity.extraData?.allocation.currency,
                        allocation: entity.extraData?.allocation.allocation,
                    } : {}),
                })),
            riskIndicators: RISK_INDICATORS_FOR_SIMULATION,
            activeRisks: state.draft.benchmark ? ACTIVE_RISKS_FOR_SIMULATION : [],
        };

        const simulationHaveToBeLaunched = this.haveToBeLaunched(state);

        if (!simulationHaveToBeLaunched) return EMPTY;
        return this.scenariosService.getScenarioSimulation(simulationParams)
            .pipe(
                tap(([simulationResult, requestId]: [SimulationScenarioResponse, string]) => {
                    patchState({
                        active: {
                            ...state.draft,
                        },
                        simulation: simulationResult,
                        loadingActiveScenario: true,
                        marketEventDisplayMode: {
                            mode: SimulationScenarioMarketEventDisplayMode.SEVERAL_EVENTS,
                            eventOrderSelected: simulationResult.references?.[0]?.srri?.eventValues?.length
                                ? simulationResult.references?.[0].srri.eventValues.length - (simulationResult?.historicalPeriod ? 2 : 1)
                                : 0,
                        },
                        requestId,
                    });
                }),
                tap(() => {
                    dispatch(new GetAuthorisationAction(true));
                }),
                takeUntil(this.actions$.pipe(ofAction(CancelLaunchSimulationAction))),
            );
    }

    @Action(CopyActiveInDraftAction)
    public copyActiveInDraft({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();
        patchState({
            draft: {
                ...state.active,
            },
        });
    }

    @Action(ChangeViewModeAction)
    changeViewMode({ patchState }: StateContext<SimulationScenarioStateModel>, action: ChangeViewModeAction) {
        patchState({
            viewMode: action.viewMode,
        });
    }

    @Action(ResetViewModeDisplayAction)
    resetViewModeDisplay({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();

        const currentViewMode = state.viewMode;

        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                ...(
                    currentViewMode === ViewModeType.PRO
                        ? {
                            PRO: SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES.PRO,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.ADVANCED
                        ? {
                            ADVANCED: SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES.ADVANCED,
                        }
                        : {}
                ),
                ...(
                    currentViewMode === ViewModeType.MAIN
                        ? {
                            MAIN: SIMULATION_SCENARIO_VIEW_MODES_DEFAULT_VALUES.MAIN,
                        }
                        : {}
                ),
            },
        });
    }

    @Action(SearchMarketEventsAction)
    searchMarketEvents({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        let state = getState();

        patchState({
            marketEvents: {
                ...state.marketEvents,
                isLoading: true,
            },
        });

        return this.marketEventsService.searchMarketEvents({
            query: state.marketEvents.query,
            moduleCode: 'SCENARIO',
            marketEventIds: [],
            marketCycleIds: state.marketEvents.filters.marketCycles.map((item) => item.code),
            contextIds: state.marketEvents.filters.contexts.map((item) => item.id),
            geographyIds: state.marketEvents.filters.geographies.map((item) => item.id),
            minDuration: state.marketEvents.filters.duration.minValue,
            maxDuration: state.marketEvents.filters.duration.maxValue,
            promoted: !state.marketEvents.query
                && !state.marketEvents.filters.duration.minValue
                && !state.marketEvents.filters.duration.maxValue
                && !state.marketEvents.filters.contexts.length
                && !state.marketEvents.filters.geographies.length
                && !state.marketEvents.filters.marketCycles.length,
            ordered: false,
        })
            .pipe(
                tap((result) => {
                    state = getState();
                    patchState({
                        marketEvents: {
                            query: state.marketEvents.query,
                            items: [...result],
                            isLoading: false,
                            filters: state.marketEvents.filters,
                        },
                    });
                }),
            );
    }

    @Action(SearchMarketEventsTextAction)
    searchMarketEventsText({
        getState,
        patchState,
    }: StateContext<SimulationScenarioStateModel>, action: SearchMarketEventsTextAction) {
        const state = getState();

        patchState({
            marketEvents: {
                ...state.marketEvents,
                query: action.query,
            },
        });
    }

    @Action(SetViewModeDisplayValuesAction)
    setViewModeDisplayValuesAction({
        getState,
        patchState,
    }: StateContext<SimulationScenarioStateModel>, action: SetViewModeDisplayValuesAction) {
        const state = getState();
        patchState({
            viewModeDisplay: {
                ...state.viewModeDisplay,
                [state.viewMode]: action.display,
            },
        });
    }

    @Action(UpdateMarketEventDisplayAction)
    UpdateMarketEventDisplayAction({ patchState }: StateContext<SimulationScenarioStateModel>, action: UpdateMarketEventDisplayAction) {
        patchState({
            marketEventDisplayMode: action.marketEventDisplay,
        });
    }

    @Action(SelectScenarioAction)
    public selectScenario({
        getState,
        patchState,
    }: StateContext<SimulationScenarioStateModel>, action: SelectScenarioAction) {
        const state = getState();
        if (state.draft.scenarioId === action.scenarioId) {
            patchState({
                draft: {
                    ...state.draft,
                    scenarioId: null,
                },
            });
        } else {
            patchState({
                draft: {
                    ...state.draft,
                    scenarioId: action.scenarioId,
                },
            });
        }
    }

    @Action(GetScenarioAction)
    public getScenario({ getState, patchState }: StateContext<SimulationScenarioStateModel>, action: GetScenarioAction) {
        return this.scenariosService.getScenario(action.scenarioId).pipe(
            tap((scenario) => {
                const state = getState();
                patchState({
                    draft: {
                        ...state.draft,
                        marketEventIds: scenario.marketEvents.map((marketEvent) => marketEvent.id),
                        historicalPeriod: scenario?.historicalPeriod || null,
                    },
                    editedScenario: {
                        ...scenario,
                    },
                });
            }),
        );
    }

    @Action(ResetCurrentScenarioAction)
    public resetCurrentScenario({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();
        patchState({
            draft: {
                ...state.draft,
                marketEventIds: null,
                historicalPeriod: null,
            },
            editedScenario: editedScenarioDefaults,
        });
    }

    @Action(UpdateMarketEventsSearchCriteriasAction)
    public updateMarketEventsSearchCriterias(
        { getState, patchState }: StateContext<SimulationScenarioStateModel>,
        action: UpdateMarketEventsSearchCriteriasAction,
    ) {
        const state = getState();
        patchState({
            marketEvents: {
                ...state.marketEvents,
                filters: action.filters,
            },
        });
    }

    @Action(InitActiveScenarioAction)
    initActiveScenario({ getState, patchState }: StateContext<SimulationScenarioStateModel>) {
        const state = getState();
        patchState({
            loadingActiveScenario: true,
        });
        if (state.active.marketEventIds && state.active.marketEventIds.length > 0) {
            const activeScenarioMarketEventIds = state.activeScenario.activeMarketEvents.map((marketEvent) => marketEvent.id);
            const shouldActiveMarketEventsBeUpdated = !equals(activeScenarioMarketEventIds, state.active.marketEventIds);
            if (shouldActiveMarketEventsBeUpdated) {
                const searchMarketEventsParams: MarketEventSearchRequest = {
                    query: '',
                    moduleCode: 'SCENARIO',
                    marketEventIds: state.active.marketEventIds,
                    marketCycleIds: [],
                    contextIds: [],
                    geographyIds: [],
                    minDuration: null,
                    maxDuration: null,
                    promoted: false,
                    ordered: false,
                };
                return this.marketEventsService.searchMarketEvents(searchMarketEventsParams)
                    .pipe(
                        tap((result) => {
                            const newActiveMarketEvents = state.active.marketEventIds?.reduce((acc: MarketEvent[], marketEventId) => {
                                const eventMarketData = result.find((marketEvent) => marketEvent.id === marketEventId);
                                return eventMarketData ? [...acc, eventMarketData] : acc;
                            }, []);
                            patchState({
                                loadingActiveScenario: false,
                                activeScenario: {
                                    ...state.activeScenario,
                                    activeHistoricalPeriod: state.active.historicalPeriod,
                                    activeMarketEvents: [...(newActiveMarketEvents ?? [])],
                                },
                            });
                        }),
                    );
            }
            if (state.activeScenario.activeHistoricalPeriod !== state.active.historicalPeriod) {
                patchState({
                    loadingActiveScenario: false,
                    activeScenario: {
                        ...state.activeScenario,
                        activeHistoricalPeriod: state.active.historicalPeriod,
                    },
                });
            }
        } else if (state.active.scenarioId) {
            return this.scenariosService.getScenario(state.active.scenarioId).pipe(
                tap((scenario) => {
                    patchState({
                        loadingActiveScenario: false,
                        activeScenario: {
                            ...state.activeScenario,
                            activeHistoricalPeriod: scenario.historicalPeriod,
                            activeMarketEvents: [...scenario.marketEvents],
                        },
                    });
                }),
            );
        }
        patchState({
            loadingActiveScenario: false,
        });
        return EMPTY;
    }

    private getSharesData(shareIds: number[]): Observable<Share[]> {
        return this.sharesService.search({
            fields: SHARES_SEARCH_FIELDS.join(','),
            q: '',
            filters: [
                {
                    exclude: false,
                    property: 'id',
                    union: true,
                    values: shareIds.map((id) => id.toString()),
                },
            ],
            sorts: [],
            searchUniverse: 0,
            subSegmentation: 'ALL',
            includeMetadata: false,
            startAt: 0,
            size: 50,
            requestId: '',
        }).pipe(
            map((result) => result.values),
        );
    }

    private getPortfoliosData(portfoliosIds: number[]): Observable<Portfolio[]> {
        return this.portfoliosService.search({
            fields: `${PORTFOLIOS_SEARCH_FIELDS.join(',')}alerts,calculatedSrri,srri,`,
            q: '',
            filters: [
                {
                    exclude: false,
                    property: 'id',
                    union: true,
                    values: portfoliosIds.map((id) => id.toString()),
                },
            ],
            sorts: [],
            portfolioUniverse: MY_PORTFOLIO_UNIVERSE_ID,
            subSegmentation: 'ALL',
            includeMetadata: false,
            startAt: 0,
            size: 50,
            requestId: '',
        }).pipe(
            map((result) => result.values),
        );
    }

    private getCategoriesData(categoryIds: number[]): Observable<readonly ShareCategory[]> {
        return this.sharesService.getCategories(categoryIds);
    }

    private haveToBeLaunched(state: SimulationScenarioStateModel) {
        if (!state.active || !state.simulation) {
            return true;
        }
        if (!equals(state.draft.historicalPeriod, state.active.historicalPeriod)
            || !equals(state.draft.marketEventIds, state.active.marketEventIds)
            || !equals(state.draft.scenarioId, state.active.scenarioId)
        ) {
            return true;
        }
        if (!equals(state.draft.benchmark, state.active.benchmark)) {
            return true;
        }
        const entitiesAdded = state.draft.comparedEntities.reduce((acc: ComparedEntity[], draftEntity) => {
            if (!state.active.comparedEntities.find((activeEntity) => activeEntity.id === draftEntity.id && activeEntity.type === draftEntity.type)) {
                acc.push(draftEntity);
            }
            return acc;
        }, []);
        if (entitiesAdded.length > 0) return true;
        const entitiesDeleted = state.active.comparedEntities.reduce((acc: ComparedEntity[], activeEntity) => {
            if (!state.draft.comparedEntities.find((draftEntity) => activeEntity.id === draftEntity.id && activeEntity.type === draftEntity.type)) {
                acc.push(activeEntity);
            }
            return acc;
        }, []);
        if (entitiesDeleted.length > 0) return true;
        return false;
    }
}
