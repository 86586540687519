// eslint-disable-next-line max-classes-per-file
import { EntityType } from '~/app/shared/enums/entity-type.enum';
import { SimulationScenarioViewModeValue } from '~/app/shared/enums/simulation-scenario-view-mode-value.enum';
import { ViewModeType } from '~/app/shared/enums/view-mode-type.enum';
import { EntityToLoad } from '~/app/shared/types/entity-to-load.type';
import {
    OperationSimulationUpdatingPortfolio,
} from '~/app/shared/types/operations/operation-simulation-updating-portfolio.type';
import { CreatePortfolio } from '~/app/shared/types/portfolio/create-portfolio.type';
import { ProjectionAllocation } from '~/app/shared/types/projection/projection-allocation.type';
import {
    SimulationCommonBenchmarkParam,
} from '~/app/shared/types/simulation-common/simulation-common-benchmark-param.type';
import {
    SimulationCommonReferenceRequest,
} from '~/app/shared/types/simulation-common/simulation-common-reference-request.type';
import {
    SimulationScenarioEditedScenario,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-edited-scenario.type';
import {
    SimulationScenarioMarketEventDisplay,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-market-event-display.type';
import {
    SimulationScenarioMarketEventsSearchCriterias,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-market-events-search-criterias.type';


export class AddCategoriesToComparedEntitiesAction {
    static readonly type = '[SimulationScenario] Add Categories To Compared Entities';

    constructor(
        public categoryIds: number[],
    ) {
    }
}

export class AddComparisonListToComparedEntitiesAction {
    static readonly type = '[SimulationScenario] Add Comparison List To Compared Entities';

    constructor(
        public listId: number,
    ) {
    }
}

export class AddSharesToComparedEntitiesAction {
    static readonly type = '[SimulationScenario] Add Shares To Compared Entities';

    constructor(
        public shares: EntityToLoad[],
    ) {
    }
}

export class AddPortfoliosToComparedEntitiesAction {
    static readonly type = '[SimulationScenario] Add Portfolios To Compared Entities';

    constructor(
        public portfolios: EntityToLoad[],
    ) {
    }
}
export class AddAllocationsToComparedEntitiesAction {
    static readonly type = '[SimulationScenario] Add allocations To Compared Entities';

    constructor(
        public allocations: OperationSimulationUpdatingPortfolio[],
    ) {
    }
}

export class AddCreatingPortfolioToComparedEntitiesAction {
    static readonly type = '[SimulationScenario] Add creating portfolio To Compared Entities';

    constructor(
        public portfolio: CreatePortfolio, public allocation: SimulationCommonReferenceRequest, public initialAllocation: ProjectionAllocation | undefined,
    ) {
    }
}

export class DeleteComparedEntityAction {
    static readonly type = '[SimulationScenario] delete compared entity';

    constructor(
        public entityId: number,
        public entityType: EntityType,
    ) {
    }
}

export class DeleteAllComparedEntitiesAction {
    static readonly type = '[SimulationScenario] delete all compared entities';
}

export class DeleteScenarioAction {
    static readonly type = '[SimulationScenario] delete scenario';

    constructor(
        public scenarioId: string,
    ) {
    }
}

export class ResetAction {
    static readonly type = '[SimulationScenario] Reset state';
}

export class ResetCurrentScenarioAction {
    static readonly type = '[SimulationScenario] Reset current scenario of state';
}

export class ToggleBenchmarkAction {
    static readonly type = '[SimulationScenario] toggle benchmark';

    constructor(
        public benchmark: SimulationCommonBenchmarkParam,
    ) {
    }
}

export class LaunchSimulationAction {
    static readonly type = '[SimulationScenario] launch simulation';
}

export class CancelLaunchSimulationAction {
    static readonly type = '[SimulationScenario] Cancel launch simulation';
}

export class CopyActiveInDraftAction {
    static readonly type = '[SimulationScenario] copy active params in draft';
}


export class ChangeViewModeAction {
    static readonly type = '[SimulationScenario] Change view mode';

    constructor(public viewMode: ViewModeType) {}
}

export class ResetViewModeDisplayAction {
    static readonly type = '[SimulationScenario] Reset view mode display';
}

export class UpdateEditedScenarioAction {
    static readonly type = '[SimulationScenario] Update edited scenario values';

    constructor(public newEditedScenario: SimulationScenarioEditedScenario) {}
}

export class SearchMarketEventsAction {
    static readonly type = '[SimulationScenario] Search market events';
}

export class SearchMarketEventsTextAction {
    static readonly type = '[SimulationScenario] Search market events text';

    constructor(public query: string | null) {}
}

export class SetViewModeDisplayValuesAction {
    static readonly type = '[SimulationScenario] Set viewMode display values';

    constructor(public display: SimulationScenarioViewModeValue[]) {}
}

export class SelectScenarioAction {
    static readonly type = '[SimulationScenario] selection of scenario';

    constructor(public scenarioId: string) {}
}

export class GetScenarioAction {
    static readonly type = '[SimulationScenario] get scenario';

    constructor(public scenarioId: string) {}
}

export class UpdateMarketEventsSearchCriteriasAction {
    static readonly type = '[SimulationScenario] Update market events search criterias';

    constructor(public filters: SimulationScenarioMarketEventsSearchCriterias) {}
}
export class UpdateMarketEventDisplayAction {
    static readonly type = '[SimulationScenario] Update market events display';

    constructor(public marketEventDisplay: SimulationScenarioMarketEventDisplay) {}
}

export class UpdateScenarioAction {
    static readonly type = '[SimulationScenario] Update scenario to back end';
}

export class CreateScenarioAction {
    static readonly type = '[SimulationScenario] Save new scenario to back end';
}

export class InitActiveScenarioAction {
    static readonly type = '[SimulationScenario] Init active scenario for result page';
}
