import {
    HttpClient,
    HttpErrorResponse,
    HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
import {
    delay,
    forkJoin,
    Observable,
    of,
    switchMap,
    throwError,
} from 'rxjs';

import {
    SimulationScenarioCreateRequest,
    SimulationScenarioUpdateRequest,
} from '~/app/shared/types/simulation-scenario/simulation-scenario-edit-request.type';
import { SimulationScenarioItem } from '~/app/shared/types/simulation-scenario/simulation-scenario-item.type';
import { SimulationScenarioRequest } from '~/app/shared/types/simulation-scenario/simulation-scenario-request.type';
import { SimulationScenarioResponse } from '~/app/shared/types/simulation-scenario/simulation-scenario-response.type';
import { SimulationScenarioSearch } from '~/app/shared/types/simulation-scenario/simulation-scenario-search.type';

const MAX_RETRY = 80;

@Injectable({
    providedIn: 'root',
})
export class ScenariosService {
    constructor(
        private http: HttpClient,
        private translocoService: TranslocoService,
    ) {}

    getScenario(id: string): Observable<Readonly<SimulationScenarioItem>> {
        return this.http.get<Readonly<SimulationScenarioItem>>(`/scenarios/${id}`);
    }

    search(query: SimulationScenarioSearch) {
        return this.http.post<Readonly<SimulationScenarioItem[]>>('/scenarios/search', query);
    }

    getScenarioSimulation(simulationParams: SimulationScenarioRequest) {
        return this.http.post<Readonly<{
            requestId: string,
            expiresAt: string,
        }>>('/scenario-simulation', simulationParams)
            .pipe(
                switchMap((simulationRequest: {
                    requestId: string,
                    expiresAt: string,
                }) => forkJoin([
                    this.processSimulation(simulationRequest.requestId),
                    of(simulationRequest.requestId),
                ])),
            );
    }

    updateScenario(scenario: SimulationScenarioUpdateRequest): Observable<SimulationScenarioItem> {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        return this.http.put<SimulationScenarioItem>(`/scenarios/${scenario.id}`, scenario);
    }

    createScenario(scenario: SimulationScenarioCreateRequest): Observable<SimulationScenarioItem> {
        return this.http.post<SimulationScenarioItem>('/scenarios', scenario);
    }

    deleteScenario(scenarioId: string) {
        return this.http.delete<SimulationScenarioItem>(`/scenarios/${scenarioId}`);
    }

    private processSimulation(requestId: string, retryCount: number = 0): Observable<SimulationScenarioResponse> {
        return this.http.get<SimulationScenarioResponse>(`/scenario-simulation/${requestId}`, {
            observe: 'response',
        })
            .pipe(
                switchMap((response: HttpResponse<SimulationScenarioResponse>) => {
                    if (response.status === 202 && retryCount < MAX_RETRY) {
                        return of(true).pipe(
                            delay(1000),
                            switchMap(() => this.processSimulation(requestId, retryCount + 1)),
                        );
                    }
                    if (response.status === 202 && retryCount >= MAX_RETRY) {
                        return throwError(() => new HttpErrorResponse({
                            error: { message: this.translocoService.translate('common.error_too_many_retry') },
                        }));
                    }
                    return of(response.body as SimulationScenarioResponse);
                }),
            );
    }
}
